.main-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-right: 10rem;
    padding-left: 2rem;
    padding-bottom: 1rem;
    margin-top: 4rem;
    overflow-y: scroll;
    max-height: 85vh;
}

table {
    max-width: 100rem;
    font-size: 1.9rem;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
    border-collapse: collapse;
    transition: all 0.3s;
}


th,
td {
    padding: 16px 24px;
    text-align: left;
    max-width: 20rem;
    overflow: auto;
}

thead tr {
    background-color: #203c4a;
    color: #fff;
}

thead th {
    width: auto;
}

tbody tr:nth-child(odd) {
    background-color: #f8f9fa;
}

tbody tr:nth-child(even) {
    background-color: #e9ecef;
}

.open-report-button {
    cursor: pointer;
    transition: all 0.1s;
}

.open-report-button:hover {
    color: #F16A22;
    font-weight: 500;
}

.last-update {
    margin-right: auto;
    margin-left: auto;
    font-size: 1.9rem;
    color: #e9ecef;

    position: absolute;
    top: 30px;
    left: 10px;

    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.last-update span {
    color: #F16A22;
    font-weight: 500;
}

.last-update .refresh-btn {
    cursor: pointer;
}

/* ***** */
/* FORMS */
/* ***** */

.main-page-input-container {
    /* box-shadow: 0 1rem 1rem 0 rgba(0, 0, 0, 0.2); */
    padding: 1.8rem 3.6rem;
    margin-bottom: 4rem;
    background-color: #d2d8db;
    border-radius: 9px;
}

.form-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3.2rem;
    row-gap: 2.4rem;
    margin-top: 3rem;
    align-items: end;
    /* background-color: #b38080; */
}

.form-container label {
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 1.2rem;
}

.form-container input,
.form-container select {
    width: 100%;
    padding: 1.2rem;
    font-size: 1.8rem;
    font-family: inherit;
    color: inherit;
    border: 2px solid #e9eced;
    border-radius: 9px;
    /* box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1); */

    background-color: #e9eced;
}

.form-container input::placeholder {
    color: #aaa;
}

.form-container button {
    /* background-color: #5c320e; */
    align-self: end;
    padding: 1.2rem;
}

.form-container:focus {
    outline: none;
    box-shadow: 0 0 0 0.8rem rgba(255, 255, 255, 0.5);
}

.form-container .btn {
    background-color: #F16A22;
    color: #e9eced;
}

.form-header {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.form-header .heading-tertiary {
    margin-bottom: 0;
}

.form-header span {
    font-weight: 600;
    color: #F16A22;
}

.empty-input {
    border: 2px solid red !important;
    background-color: rgb(255, 186, 186) !important;
}