.img-container {
    border-radius: 9px;
    overflow: hidden;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
}

.tvia-sub:hover {
    scale: 1.15;
    transition: all 0.4s;
}

.link-to-asset-details {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}