.auth-form-container {
    max-width: 80rem;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.auth-header {
    display: flex;
    align-items: center;
    gap: 2rem;

    margin-top: 2rem;
    margin-left: 4rem;
}

.auth-logo {
    height: 13rem;
}

.auth-heading-primary {
    font-weight: 700;
    color: #F16A22;
    letter-spacing: -0.5px;
    font-size: 5.2rem;
    line-height: 1.05;
    margin-bottom: 1.6rem;
}

.auth-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: baseline;

    margin: 10rem 0 10rem 10rem;
    max-width: 40rem;

    box-shadow: 0 1rem 1rem 0 rgba(0, 0, 0, 0.2);
    padding: 3rem 6rem;
    border-radius: 9px;
    background-color: #ccc;
}

.auth-form label {
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 1.2rem;
}

.auth-form input {
    width: 100%;
    padding: 1.2rem;
    font-size: 1.8rem;
    font-family: inherit;
    color: inherit;
    border: 2px solid #fff;
    border-radius: 9px;

    background-color: #fff;
}

.auth-form input::placeholder {
    color: #aaa;
}

.auth-form .btn {
    box-shadow: none;
}

.auth-footer {
    margin-left: 4rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.auth-footer p {
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 600;
    font-style: italic;
    color: #555;
    letter-spacing: 0.75px;
}