.tools-nav-list-item__label {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    font-size: 2.7rem;
    cursor: pointer;
    transition: all 0.1s;
}

.tools-nav-list-item__label:hover {
    color: #fff;
    font-weight: 500;
}