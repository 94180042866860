.logo-container {
    background-color: #e9eced;
}

.logo-container--long {
    padding: 1.4rem 4.6rem;
}

.logo-container--short {
    padding: 1.4rem 2.3rem;
}

.header-logo {
    width: 100%;
}