.tools-nav {
    flex-shrink: 0;
    background-color: #203c4a;

    padding: 3rem 1.5rem;
    width: 100%;
}

.tools-nav-list {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    justify-content: flex-start;

    color: #e9eced;
    height: 100%;
}

.tools-nav-list-item:nth-last-child(4) {
    margin-bottom: auto;
}

.tools-nav-list-item {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.tools-nav-list-item__list {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    font-size: 2.2rem;
}

.tools-nav-list-item__list--long {
    padding-left: 4rem;
}

.tools-nav-list-item__list--short {
    padding-left: 0.2rem;
    font-size: 1.8rem;
}

.tools-nav-list-item__list li {
    cursor: pointer;
    transition: all 0.1s;
}

.tools-nav-list-item__list li.active-tool {
    cursor: default;
}

.tools-nav-list-item__list li:hover {
    color: #fff;
    font-weight: 500;
}