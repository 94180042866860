.back-button {
    margin-top: 1rem;

}

.back-button,
.back-button:link,
.back-button:visited {
    cursor: pointer;
    color: #d95f1f;
    border-radius: 50%;
    border: none;

    padding: 1rem 2rem;

    font-size: 2.0rem;
    background-color: transparent;

    display: flex;
    gap: 0.5rem;
    align-items: center;

    position: fixed;
    bottom: 20px;
    right: 30px;
}

.back-button:hover {
    scale: 1.1;
}

.btn:active {
    scale: 1;
}