.header-nav-container {
    width: 100%;
    background-color: #203c4a;
    color: #e9eced;
    height: 8rem;
}

.user-nav-list {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    height: 100%;
}

.user-nav-list li {
    padding: 0.6rem 1.2rem;
    transition: all 0.1s;
}

.li-link {
    cursor: pointer;
}

.li-link:hover {
    color: #fff;
    font-weight: 500;
}

.user-role {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 2.2rem;
}

.user-name-pic {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    font-size: 2.2rem;
    position: relative;
}

.user-name-pic .user-pic {
    font-size: 3rem;
}

.user-list {
    background-color: #203c4a;
    color: #e9eced;
    position: absolute;
    bottom: -50px;
    left: 0;
    border-bottom-left-radius: 9px;

    padding: 1.4rem 2.8rem;
    width: 100%;
}

.user-list p {
    display: flex;
    gap: 0.8rem;
    align-items: center;
}