.alarm {
    display: flex;
    align-items: center;
    gap: 1rem;
    height: max-content;
}

.alarm span {
    padding: 1rem;
    font-size: 3rem;
}

.alarm--on {
    background-color: #ffe066;
}

.alarm--off {
    background-color: #8cd98c;
}

.btn--cocrae {
    margin-bottom: 2.5rem;
}