.dashboard-page {
    display: flex;
}

.active-tool {
    font-weight: 700 !important;
    color: #f16a22 !important;
}

.hide {
    display: None !important;
}

strong {
    color: #f16a22;
}