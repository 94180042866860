.config-container {
    width: 80rem;
}

.config-container .subheading {
    margin-top: 4rem;
    margin-bottom: 3rem;
}

.config-container .btn {
    margin-top: 4rem;
    box-shadow: none;
}

/* Toggle Input */
.toggler-wrapper {
    display: block;
    width: 45px;
    height: 25px;
    cursor: pointer;
    position: relative;
}

.toggler-wrapper input[type="checkbox"] {
    display: none;
}

.toggler-wrapper input[type="checkbox"]:checked+.toggler-slider {
    background-color: #F16A22;
}

.toggler-wrapper .toggler-slider {
    background-color: #ccc;
    position: absolute;
    border-radius: 100px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
}

.toggler-wrapper .toggler-knob {
    position: absolute;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
}

.toggler-wrapper.style-1 input[type="checkbox"]:checked+.toggler-slider .toggler-knob {
    left: calc(100% - 19px - 3px);
}

.toggler-wrapper.style-1 .toggler-knob {
    width: calc(25px - 6px);
    height: calc(25px - 6px);
    border-radius: 50%;
    left: 3px;
    top: 3px;
    background-color: #203C4A;
}

.option-container {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    margin-bottom: 2rem;
}

.badge {
    font-size: 1.8rem;
    color: #203C4A;
}