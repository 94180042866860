.documentation-container {
    max-width: 100rem;
    margin: 0 auto;
}

.documentation-container h2 {
    margin-bottom: 3rem;
    margin-top: 6rem;
}

.documentation-container .grid {
    align-items: center;
    justify-items: center;
}

.documnetation-item-container {
    background-size: cover;
    color: #1a303b;
    border-radius: 9px;
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.15);

    width: 30rem;
    height: 12rem;
    text-align: center;
    color: #eee !important;
    font-size: 3rem;
    font-weight: 700;

    transition: all 0.3s;
}

.documnetation-item-container--CoCRAE {
    background-image: linear-gradient(rgba(24, 24, 24, 0.6), rgba(32, 32, 32, 0.6)), url(./img/cocrae.jpg);
}

.documnetation-item-container--TVIA {
    background-image: linear-gradient(rgba(24, 24, 24, 0.6), rgba(32, 32, 32, 0.6)), url(./img/tvia.jpg);
}

.documnetation-item-container--HVA {
    background-image: linear-gradient(rgba(24, 24, 24, 0.6), rgba(32, 32, 32, 0.6)), url(./img/hva.jpg);
}

.documnetation-item-container--TIM {
    background-image: linear-gradient(rgba(24, 24, 24, 0.6), rgba(32, 32, 32, 0.6)), url(./img/tim.jpg);
}

.documnetation-item-container--DIM {
    background-image: linear-gradient(rgba(24, 24, 24, 0.6), rgba(32, 32, 32, 0.6)), url(./img/dim.jpg);
}

.documnetation-item-container--BAE {
    background-image: linear-gradient(rgba(24, 24, 24, 0.6), rgba(32, 32, 32, 0.6)), url(./img/bae.jpg);
}

.documnetation-item-container--API-CDS {
    background-image: linear-gradient(rgba(24, 24, 24, 0.6), rgba(32, 32, 32, 0.6)), url(./img/api.webp);
}

.documnetation-item-container--web {
    background-image: linear-gradient(rgba(24, 24, 24, 0.6), rgba(32, 32, 32, 0.6)), url(./img/web.jpg);
}

.documnetation-item-container--dels {
    background-image: linear-gradient(rgba(24, 24, 24, 0.6), rgba(32, 32, 32, 0.6)), url(./img/dels.jpg);
}

.documnetation-item-container:hover {
    scale: 1.1;
    box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.2);
    color: #F16A22 !important;
}