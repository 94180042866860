.img-container {
    border-radius: 9px;
    overflow: hidden;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
}

.hva-sub {
    max-width: 40rem;
    max-height: 40rem;
}

.hva-sub:hover {
    scale: 1.15;
    transition: all 0.4s;
}

.assess--true {
    background-color: #8cd98c;
}

.assess--false {
    background-color: #ffcccc;
}

/* HVA Form */
.choice {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.choice label {
    width: 1rem;
}

.radio-button {
    margin-bottom: 1.2rem;
    opacity: 70;
    height: 1.5rem;
    background-color: #fff;
}

.departments {
    grid-column: 1 / 3;
    background-color: #bdc2c5;
    padding: 1.6rem 3.2rem;
    border-radius: 9px;
}

.departments h4 {
    text-align: center;
}

.recipients-container {
    margin-top: 1.5rem;
}

.recipient-form {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 1fr 1fr;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
}

.recipient-form__first {
    grid-row: 1 / 2;
    grid-column: 1 / 5;
}

.recipient-form__last {
    grid-row: 1 / 2;
    grid-column: 5/ 9;
}

.recipient-form__email {
    grid-row: 2 / 3;
    grid-column: 1 / 8;
}

.recipients-container .btn {
    padding: 0.8rem;
    font-size: 2.8rem;
    font-weight: 300;
}