/* ************ */
/* IMPORT FONTS */
/* ************ */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700;800&display=swap');

/* ****************** */
/* GENERAL COMPONENTS */
/* ****************** */
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

*:focus {
  outline: none;
  /* box-shadow: 0 0 0 0.8rem rgba(161, 88, 24, 0.5); */
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  box-sizing: border-box;
}

body {
  line-height: 1;
  color: #555;
  background-color: #e9eced;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;

  overflow-y: hidden;
}

strong {
  font-weight: 500;
}

.hidden {
  display: none !important;
}

/* ******************* */
/* REUSABLE COMPONENTS */
/* ******************* */
.container {
  max-width: 120rem;
  padding: 0 3.2rem;
  margin: 3rem auto;
}

.grid {
  display: grid;
  row-gap: 6.4rem;
  column-gap: 6.4rem;
}

.grid--center-v {
  align-items: center;
}

.grid--2-cols {
  grid-template-columns: repeat(2, 1fr);
}

.grid--3-cols {
  grid-template-columns: repeat(3, 1fr);
}

.grid--4-cols {
  grid-template-columns: repeat(4, 1fr);
}

.grid--5-cols {
  grid-template-columns: repeat(5, 1fr);
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* **** */
/* TEXT */
/* **** */

.normal {
  font-size: 1.8rem;
  line-height: 1.2;
}

.heading-primary,
.heading-secondary,
.heading-tertiary {
  font-weight: 700;
  color: #555;
  letter-spacing: -0.5px;
}

.heading-primary {
  font-size: 5.2rem;
  line-height: 1.05;
  margin-bottom: 3.2rem;
}

.heading-secondary {
  font-size: 4.4rem;
  line-height: 1.2;
  margin-bottom: 0.8rem;
}

.heading-tertiary {
  font-size: 3.0rem;
  line-height: 1.2;
  margin-bottom: 3.2rem;
  color: #a15818;
}

.subheading {
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 600;
  color: #a15818;
  margin-bottom: 1.6rem;
  letter-spacing: 0.75px;
}

/* *************** */
/* BUTTONS & LINKS */
/* *************** */

.btn,
.btn:link,
.btn:visited {
  cursor: pointer;
  border: none;
  text-decoration: none;
  border-radius: 9px;

  padding: 1rem 2rem;
  box-shadow: 0 1rem 1rem 0 rgba(0, 0, 0, 0.15);

  font-size: 2.0rem;
  font-weight: 500;
  display: inline-block;
  transition: all 0.3s;

  background-color: #f27938;
  color: #e9eced;
}

.btn:hover {
  scale: 1.05;
  box-shadow: 0 1rem 1.3rem 0 rgba(0, 0, 0, 0.25);

  background-color: #d95f1f;
}

.btn:active {
  scale: 1;
  box-shadow: 0 1rem 1rem 0 rgba(0, 0, 0, 0.15);

  background-color: #f27938;
}

a:link,
a:visited {
  text-decoration: none;
  transition: all 0.1s;
  color: inherit;
}

a:hover,
a:active {
  font-weight: 700;
  color: #f16a22
}

li {
  list-style: none;
}