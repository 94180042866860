.documentation-item {
    max-width: 100rem;
    margin: 10rem auto;
}

.documentation-item li {
    margin-bottom: 0.7rem;
}

.documentation-item p {
    text-align: justify;
}