.report-info-box-bg {
    backdrop-filter: blur(10px);

    width: 100%;
    height: 100%;
    position: absolute;
    top: 9rem;
    left: 0;

}

.report-info-box {
    padding: 5rem 10rem;
    background-color: #203C4A;
    color: #e9eced;
    border: solid 3px #e9eced;
    line-height: 150%;

    border-radius: 8px;
    box-shadow: 0 0 5rem 0 rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(10px);

    position: relative;
    z-index: 10;
    overflow-y: scroll;

    height: 70%;
}

.close-btn {
    /* font-size: 4rem; */
    font-weight: 500;
    color: #e9eced;
    cursor: pointer;
    position: absolute;

    top: 20px;
    right: 20px;

    transition: all 0.2s;
}

.close-btn:hover {
    transform: scale(1.15);
}