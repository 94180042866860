.menu-button {
    background-color: #203C4A;
    align-self: center;
    align-self: stretch;
    width: 8rem;
}

.menu-button-container {
    cursor: pointer;
    padding: 8px;
    border-radius: 50%;
    background-color: transparent;
    transition: all 0.1s;
    color: #e9eced;
}

.menu-button-container:hover {
    background-color: #36505c;
}

.tools-nav-btn {
    font-size: 3.4rem;
}