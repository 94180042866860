/* ************** */
/* HEADER SECTION */
/* ************** */
.home-page__header {
    /* margin-bottom: 10rem; */
    padding: 1.2rem;

    width: 100%;

    border-radius: 40px;
    box-shadow: 0 0px 10px 5px rgba(0, 0, 0, 0.2);

    background-color: #203C4A;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5rem;
}

.home-page-logo-container {
    background-color: rgba(255, 255, 255, 0.8);
    width: 12rem;
    height: 12rem;
    padding: 1rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-page-logo {
    width: 95%;
}

.home-page-headings-container {
    background-color: rgba(255, 255, 255, 0.8);
    height: 12rem;
    padding: 1rem 2rem;
    border-radius: 50px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


/* ************ */
/* MAIN SECTION */
/* ************ */

.home-page__main {
    width: 100%;
    padding: 2rem;
}

.home-page__main .heading-tertiary {
    margin-top: 10rem;
}

/* ************ */
/* COMP SECTION */
/* ************ */

.home-page__main__components {
    justify-items: center;
    font-size: 1.6rem;
    column-gap: 1rem;
    text-align: left;
}

.home-page__component-card {
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);

    display: flex;

    width: 90%;
    height: 20rem;
    padding: 1.8rem 1.8rem;
    border-radius: 30px;

    color: #203C4A;
    overflow-y: auto;
}

.home-page__main__components a:hover {
    font-weight: 400;
    transform: scale(1.05);
}

.home-page__component-card__titles {
    text-align: right;
    width: 40%;

    border-right: solid 2px #F16A22;
    padding-right: 2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
}

.home-page__component-card__desc {
    padding-left: 2rem;
}

/* ************ */
/* USEF SECTION */
/* ************ */

.home-page__useful {
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);

    width: 30rem;
    padding: 1.8rem 3.6rem;
    border-radius: 30px;

    color: #203C4A;
    overflow-y: auto;
}

.home-page__useful__name {
    color: #F16A22;
    font-weight: 500;
    margin-bottom: 1rem;
}