.double-row {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

li {
    margin-bottom: 1rem;
}

table {
    max-width: 140rem;
}