.nav-bar-section {
    height: 100vh;
    display: grid;

    grid-template-rows: 8rem 1fr;
}

.nav-bar-section--open {
    grid-template-columns: 27rem auto;
}

.nav-bar-section--closed {
    grid-template-columns: 10rem auto;
}