/* ************** */
/* *** PAGINA *** */
/* ************** */

ul.react-paginate {
    margin-bottom: 2rem;
    margin-top: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style-type: none;
    padding: 0 5rem;
}

ul.react-paginate li a {
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
}

ul.react-paginate li.previous a,
ul.react-paginate li.next a,
ul.react-paginate li.break a {
    border-color: transparent;
}

ul.react-paginate li.selected a {
    background-color: #203C4A;
    color: #F16A22;
    border-color: transparent;
    min-width: 32px;
}

ul.react-paginate li.disabled a {
    color: grey;
}

ul.react-paginate li.disable,
ul.react-paginate li.disabled a {
    cursor: default;
}

ul.react-paginate a:hover {
    font-weight: 400;
}

/* ************** */
/* *** EVENTS *** */
/* ************** */

.tim-events-main {
    overflow-y: scroll;
    height: 90%;
    padding: 2rem;
}

.tim-events-main table:hover {
    transform: none;
}

.tim-events-container {
    display: grid;

    grid-template-columns: 1fr 1fr;
    column-gap: 10rem;
    row-gap: 3rem;
    justify-items: end;
    align-items: stretch;
}

.tim-events-container__events {
    justify-self: start;
}

.tim-related-events-container {
    background-color: #f8f9fa;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 4rem;
}

.tim-related-events-container-event {
    background-color: #e9ecef;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.2rem 2.4rem;
}

.tim-events-container__objects {
    grid-column: 1/3;
    justify-self: center;
    width: 80%;
}

.tim-events-objects-container {
    margin-bottom: 3rem;
}

.tim-events-objects-header {
    background-color: #203C4A;
    color: #e9eced;

    padding: 1.5rem 3rem;
}

.tim-events-objects-header p {
    margin-bottom: 0.7rem;
}

.tim-events-objects-body {
    border: solid 4px #203C4A;
    padding: 1.5rem 3rem;
}

.tim-events-objects-attr {
    display: grid;
    grid-template-columns: 1.5fr 0.3fr 1.1fr 3fr;
    column-gap: 2rem;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;

    border: solid 1px #bbb;

}